import SvgLinesUrl from '@img/img-line-pattern.svg';
import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import Image from '@stories/Components/Media/Image/Image';
import React from 'react';
import { FeaturedCardStyles } from './FeaturedCard.styles';
const FeaturedCard = React.forwardRef((props) => {
    return (React.createElement(FeaturedCardStyles.Card, { draggable: false },
        React.createElement(FeaturedCardStyles.Content, null,
            props.tag && React.createElement(FeaturedCardStyles.Tag, null, props.tag),
            React.createElement(FeaturedCardStyles.Title, null, props.title),
            React.createElement(FeaturedCardStyles.ParagraphContent, null, props.description),
            props.cta && (React.createElement(LinkButton, { title: props.cta?.title, href: props.cta?.url, branding: props.cta?.branding ? props.cta?.branding : 'white' }))),
        React.createElement(FeaturedCardStyles.Media, null,
            React.createElement(FeaturedCardStyles.MediaEffect, { src: SvgLinesUrl, loading: "lazy", role: "presentation" }),
            React.createElement(FeaturedCardStyles.ImageHolder, null,
                React.createElement(Image, { ...props.image, objectFit: "cover", alt: props.title || '', draggable: false })))));
});
FeaturedCard.displayName = 'FeaturedCard';
export default FeaturedCard;
