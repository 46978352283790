import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled from 'styled-components';
const Card = styled.div `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  color: ${brand.black};
  position: relative;
  padding: 30px 0 0;

  @media ${until(Device.TabletLarge)} {
    grid-template-rows: 1fr clamp(260px, 33vw, 380px);
  }

  @media ${from(Device.TabletLarge)} {
    gap: 32px;
    padding: 30px 0;
  }
`;
const Media = styled.div `
  position: relative;
  grid-column: 1 / span 12;
  height: 100%;

  @media ${from(Device.TabletLarge)} {
    grid-column: 6 / span 7;
    height: 100%;
    margin: auto 0;
    max-height: clamp(200px, 33vw, 100%);
  }
`;
const MediaEffect = styled.img `
  position: absolute;
  top: -10px;
  right: -10px;
  width: 300px;
  height: auto;
  transition: transform 0.3s;
  color: ${brand.primary.base};
  object-fit: cover;

  @media ${from(Device.TabletLarge)} {
    width: 50%;
    height: 80%;
    top: -30px;
    right: -30px;
  }
`;
const ImageHolder = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
`;
const Content = styled.div `
  grid-column: 1 / span 12;
  padding: 30px 0;

  @media ${from(Device.TabletLarge)} {
    grid-column: 1 / span 5;
    padding: 70px 0 160px;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: 2 / span 4;
  }

  ${ParagraphStyles.Paragraph} {
    margin-bottom: 0px;
  }
`;
const Tag = styled.span `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
  color: ${brand.white};
  margin-bottom: 13px;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
  }
`;
const Title = styled.h3 `
  margin: 0px;
  color: ${brand.white};
  font-size: 32px;
  margin-bottom: 20px;

  @media ${from(Device.Tablet)} {
    font-size: 50px;
    line-height: 63px;
    margin-bottom: 30px;
  }
`;
const ParagraphContent = styled.p `
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${brand.white};

  @media ${from(Device.Tablet)} {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 28px;
  }
`;
export const FeaturedCardStyles = {
    Card,
    ImageHolder,
    Media,
    MediaEffect,
    Content,
    ParagraphContent,
    Tag,
    Title,
};
